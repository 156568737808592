<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Bize Ulaşın</h2>

        <div class="formhead">
          <div>
            <p>
              Talep, öneri ve şikâyetleriniz için aşağıda yer alan iletişim
              formumuzu kullanabilirsiniz.
            </p>
          </div>
          <div class="half">
            <div class="btn" style="margin-right:20px">
              <a @click.prevent="SET_MODAL('ModalAydinlatmaIletisim')"
                >İletişim Aydınlatma Metni</a
              >
            </div>
          </div>
          <br />
        </div>

        <div class="formpart">
          <div class="formfull">
            <div class="formleft">
              <form action="#" onSubmit="return false;" id="tab1">
                <div class="halfgroup">
                  <div class="half">
                    <div class="formline">
                      <div class="adresler">
                        <div class="adres">
                          <b class="test">Merkez</b><br />
                          TOBB İkiz Kuleleri C Blok Dumlupınar Bulv. No:252,
                          06530<br />
                          Çankaya / Ankara
                          <br />
                          <div id="map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2806.61106234359!2d32.7593593628685!3d39.90851754508116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d347d31433bb39%3A0xa12217cd66771756!2sTOBB!5e0!3m2!1sen!2suk!4v1579705053680!5m2!1sen!2suk"
                              width="600"
                              height="450"
                              frameborder="0"
                              style="border: 0"
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>
                        <br />
                        <div class="adres">
                          <b class="test">İstanbul Ofis</b><br />
                          Esentepe Mah. Harman 1 Sokak No:10 TOBB Plaza 34394<br />
                          Şişli / İstanbul
                          <br />
                          <div id="map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2453.4345152898063!2d29.00722528842995!3d41.0804573097963!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2fd659886b452c29!2s%C4%B0leti%20Y%C3%B6netim%20Sistemi!5e0!3m2!1sen!2str!4v1576493749010!5m2!1sen!2str"
                              width="600"
                              height="450"
                              frameborder="0"
                              style="border: 0"
                              allowfullscreen=""
                            >
                            </iframe>
                          </div>
                        </div>
                        <div class="adres">
                          <b class="test phone blue center"
                            ><img src="@/assets/img/ico_phone.svg" alt="" />0850
                            333 3338</b
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="half">
                    <div class="formline">
                      <div
                        :class="{ notvalid: problem.fullName }"
                        class="inputline"
                      >
                        <label>Ad Soyad:</label>
                        <input
                          type="text"
                          name="fullName"
                          v-model="fullName"
                          @input="problem.fullName = ''"
                        />
                        <div class="hint">{{ problem.fullName }}</div>
                      </div>

                      <div class="inputline">
                        <email
                          v-model="email"
                          :message="problem.email"
                          @input="problem.email = ''"
                        >
                        </email>
                      </div>

                      <div class="inputline">
                        <capcha
                          class="inputline"
                          v-model="captcha"
                          section="CONTACT-US"
                          :message="problem.captchaCode"
                          @input="problem.captchaCode = ''"
                        >
                        </capcha>
                      </div>

                      <div
                        :class="{ notvalid: problem.message }"
                        class="inputline"
                      >
                        <div class="charcount">
                          <label>Mesajınız</label>
                          <div class="num">{{ messageLen }}/1000</div>
                        </div>
                        <textarea
                          name
                          id
                          cols="25"
                          rows="6"
                          v-model="message"
                          @input="problem.message = ''"
                          maxlength="1000"
                        ></textarea>
                        <div class="hint">{{ problem.message }}</div>
                      </div>
                    </div>

                    <div
                      class="btn"
                      :class="{ dsbl: !gonder_active }"
                      @click="gonder_active ? GONDER() : null"
                    >
                      <a>Gönder</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomError from "../../misc/errors";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      fullName: "",

      email: {
        str: "",
        validated: false,
      },

      captcha: {
        captchaId: "",
        captchaCode: "",
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: "",
        message: "",
      },

      message: "",
    };
  },

  mounted() {
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true,
      };
      this.message = "Ne zaman kullanabileceğiz?";
    }
  },

  computed: {
    messageLen() {
      return this.message.length;
    },

    gonder_active() {
      return true;
      return (
        this.captcha.captchaCode.length > 1 &&
        this.fullName.length > 2 &&
        this.email.str.length > 3 &&
        this.message.length > 5
      );
    },
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "HIDE_DUYURU"]),

    clearFields() {
      this.fullName = "";
      this.email.str = "";
      this.message = "";
    },

    async GONDER() {
      this.$store
        .dispatch("app/contact_us", {
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha,
          type: "MESAJ",
          message: this.message,
        })
        .then(async (res) => {
          if (res.ok) {
            // _paq.push(["trackEvent", "Iletisim", "Talep_Gonder", "Success"]);
            await this.Alert(
              "Mesajınız bize ulaştı. İlginiz için teşekkür ederiz.",
              "success"
            );
            this.clearFields();
            this.$root.$emit("reset_capcha");
          }
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Iletisim", "Talep_Gonder", "Error"]);
          this.HandleValidationError(cerror);
        });
    },
  },
};
</script>

<style scoped>
iframe {
  margin: 0;
  height: 20vh;
  width: 100%;
}
.halfgroup {
  align-items: flex-start;
}
.map {
  height: -webkit-fill-available;
}

.formline {
  padding: 1vw;
}

.adres {
  margin-top: 1vw;
}

.phone {
  display: flex;
}
.phone img {
  height: 1rem;
  margin-right: 0.2vw;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .formpart form .half,
  .half {
    width: 100% !important;
  }

  .formline .adresler {
    flex-direction: column;
  }
  .formline .adres {
    width: 100%;
    padding: 3vw;
  }
  .formline {
    padding: 3vw;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
  .formpart form .half,
  .half {
    width: 100% !important;
  }
}
</style>
